import axios from '@axios'
import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

const getDefaultState = () => ({
  currentRouteName: '',
  applicationId: '',
  familyId: '',
  tableColumns: [],
  perPage: 25,
  totalItems: 0,
  currentPage: 1,
  perPageOptions: PER_PAGE_OPTIONS,
  searchQuery: '',
  filterPaymentMethod: '',
  filterStatus: '',
  filterDate: '',
  sortBy: 'payment_date',
  isSortDirDesc: true,
  tableViewMode: 'All Transactions',
  dataMeta: {
    from: 0,
    to: 0,
    of: 0,
  },
  filterPaymentMethodOptions: [],
  filterStatusOptions: [],
  selectedTransaction: null,
  transactionToRefund: null,
  actionType: '',
  selectedApplicationId: '',
  selectedCardId: '',
  selectedUserId: '',
  selectedChargeId: '',
  selectedRefundChargeId: '',
  popupType: '',
  paymentPopupTitle: '',
  paymentPopupBtnTitle: '',
  deletableTransactionId: null,
  confirmationModalToggle: false,
  holdPaymentItem: null,
  resumePaymentItem: null,
  deletableChargeId: null,
  confirmationDeleteChargeModalToggle: false,
  confirmationBulkDeleteTransactionModalToggle: false,
  isBulkDeleteLoading: false,
  markedElements: [],
  elementsPerPageCheckbox: false,
  isChargeDeleting: false,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getCurrentRouteName: state => state.currentRouteName,
    getIsProgramTransactions: state => state.currentRouteName === 'admin-program-transactions',
    getIsAllTransactions: state => state.currentRouteName === 'admin-transactions',
    getIsApplicationTransactions: state => state.currentRouteName === 'admin-application-edit',
    getIsParentTransactions: state => state.currentRouteName === 'admin-parent-profile' || state.currentRouteName === 'admin-family-view',
    getApplicationId: state => state.applicationId,
    getTableColumns: state => state.tableColumns,
    getFilteredTableColumns: state => state.tableColumns.filter(item => {
      if (item.key === 'model_type') {
        return !['Charges', 'Payments'].includes(state.tableViewMode)
      }
      if (item.key === 'program_name') {
        return !['admin-program-transactions', 'admin-application-edit'].includes(state.currentRouteName)
      }
      return true
    }),
    getVisibleTableColumns: state => state.tableColumns.filter(item => {
      if (item.key === 'model_type') {
        return item.selected && !['Charges', 'Payments'].includes(state.tableViewMode)
      }
      if (item.key === 'program_name') {
        return item.selected && !['admin-program-transactions', 'admin-application-edit'].includes(state.currentRouteName)
      }
      return item.selected
    }),
    getPerPage: state => state.perPage,
    getTotalItems: state => state.totalItems,
    getCurrentPage: state => state.currentPage,
    getPerPageOptions: state => state.perPageOptions,
    getSearchQuery: state => state.searchQuery,
    getFilterPaymentMethod: state => state.filterPaymentMethod,
    getFilterStatus: state => state.filterStatus,
    getFilterDate: state => state.filterDate,
    getSortBy: state => state.sortBy,
    getIsSortDirDesc: state => state.isSortDirDesc,
    getTableViewMode: state => state.tableViewMode,
    getDataMeta: state => state.dataMeta,
    getFilterPaymentMethodOptions: state => state.filterPaymentMethodOptions,
    getFilterStatusOptions: state => state.filterStatusOptions,
    getSelectedTransaction: state => state.selectedTransaction,
    getTransactionToRefund: state => state.transactionToRefund,
    getActionType: state => state.actionType,
    getSelectedApplicationId: state => state.selectedApplicationId,
    getSelectedCardId: state => state.selectedCardId,
    getSelectedUserId: state => state.selectedUserId,
    getSelectedChargeId: state => state.selectedChargeId,
    getSelectedRefundChargeId: state => state.selectedRefundChargeId,
    getPopupType: state => state.popupType,
    getPaymentPopupTitle: state => state.paymentPopupTitle,
    getPaymentPopupBtnTitle: state => state.paymentPopupBtnTitle,
    getDeletableTransactionId: state => state.deletableTransactionId,
    getConfirmationModalToggle: state => state.confirmationModalToggle,
    getHoldPaymentItem: state => state.holdPaymentItem,
    getResumePaymentItem: state => state.resumePaymentItem,
    getDeletableChargeId: state => state.deletableChargeId,
    getConfirmationDeleteChargeModalToggle: state => state.confirmationDeleteChargeModalToggle,
    getConfirmationBulkDeleteTransactionModalToggle: state => state.confirmationBulkDeleteTransactionModalToggle,
    getIsBulkDeleteLoading: state => state.isBulkDeleteLoading,
    getMarkedElements: state => state.markedElements,
    getElementsPerPageCheckbox: state => state.elementsPerPageCheckbox,
    getIsChargeDeleting: state => state.isChargeDeleting,
    getFiltersParams: state => {
      const queryParams = {
        perPage: state.perPage,
        page: state.currentPage,
        sortBy: state.sortBy,
        sortDesc: state.isSortDirDesc,
      }

      if (state.applicationId) queryParams.applicationId = state.applicationId

      if (state.familyId) queryParams.familyId = state.familyId

      if (state.filterPaymentMethod) queryParams.type = state.filterPaymentMethod

      if (state.filterStatus) queryParams.status = state.filterStatus

      if (state.filterDate) queryParams.createdAt = state.filterDate

      if (state.searchQuery) queryParams.userName = state.searchQuery

      if (state.tableViewMode === 'Charges') queryParams.is_without_charge = 0

      if (state.tableViewMode === 'Payments') queryParams.is_without_charge = 1

      if (state.tableViewMode === 'Unlinked') queryParams.only_unlinked = 1

      if (state.tableViewMode === 'CashBank') queryParams.is_cashbank = 1

      if (state.tableViewMode === 'Refunds') queryParams.type = 'Refund'

      return queryParams
    },
  },
  mutations: {
    SET_CURRENT_ROUTE_NAME: (state, val) => {
      state.currentRouteName = val
    },
    SET_APPLICATION_ID: (state, val) => {
      state.applicationId = val
    },
    SET_FAMILY_ID: (state, val) => {
      state.familyId = val
    },
    SET_TABLE_COLUMNS: (state, val) => {
      state.tableColumns = val
      localStorage.setItem('transactionsTableColumns', JSON.stringify(val))
    },
    SET_PER_PAGE: (state, val) => {
      state.perPage = val
    },
    SET_TOTAL_ITEMS: (state, val) => {
      state.totalItems = val
    },
    SET_CURRENT_PAGE: (state, val) => {
      state.currentPage = val
    },
    SET_SEARCH_QUERY: (state, val) => {
      state.searchQuery = val
    },
    SET_FILTER_PAYMENT_METHOD: (state, val) => {
      state.filterPaymentMethod = val
    },
    SET_FILTER_STATUS: (state, val) => {
      state.filterStatus = val
    },
    SET_FILTER_STATUS_OPTIONS: (state, val) => {
      state.filterStatusOptions = val
    },
    SET_FILTER_DATE: (state, val) => {
      state.filterDate = val
    },
    SET_SORT_BY: (state, val) => {
      state.sortBy = val
    },
    SET_IS_SORT_DIR_DESC: (state, val) => {
      state.isSortDirDesc = val
    },
    SET_TABLE_VIEW_MODE: (state, val) => {
      state.tableViewMode = val
    },
    SET_DATA_META: (state, val) => {
      state.dataMeta = val
    },
    SET_FILTER_PAYMENT_METHOD_OPTIONS: (state, val) => {
      state.filterPaymentMethodOptions = val
    },
    SET_SELECTED_TRANSACTION: (state, val) => {
      state.selectedTransaction = val
    },
    SET_TRANSACTION_TO_REFUND: (state, val) => {
      state.transactionToRefund = val
    },
    SET_ACTION_TYPE: (state, val) => {
      state.actionType = val
    },
    SET_SELECTED_APPLICATION_ID: (state, val) => {
      state.selectedApplicationId = val
    },
    SET_SELECTED_CARD_ID: (state, val) => {
      state.selectedCardId = val
    },
    SET_SELECTED_USER_ID: (state, val) => {
      state.selectedUserId = val
    },
    SET_SELECTED_CHARGE_ID: (state, val) => {
      state.selectedChargeId = val
    },
    SET_SELECTED_REFUND_CHARGE_ID: (state, val) => {
      state.selectedRefundChargeId = val
    },
    SET_PAYMENT_POPUP_OPTIONS: (state, payload) => {
      state.selectedUserId = payload.selectedUserId
      state.selectedCardId = payload.selectedCardId
      state.selectedApplicationId = payload.selectedApplicationId
      state.selectedTransaction = payload.selectedTransaction
      state.popupType = payload.popupType
      state.paymentPopupTitle = payload.paymentPopupTitle
      state.paymentPopupBtnTitle = payload.paymentPopupBtnTitle
    },
    SET_DELETABLE_TRANSACTION_ID: (state, val) => {
      state.deletableTransactionId = val
    },
    SET_CONFIRMATION_MODAL_TOGGLE: (state, val) => {
      state.confirmationModalToggle = val
    },
    SET_DELETABLE_CHARGE_ID: (state, val) => {
      state.deletableChargeId = val
    },
    SET_CONFIRMATION_DELETE_CHARGE_MODAL_TOGGLE: (state, val) => {
      state.confirmationDeleteChargeModalToggle = val
    },
    SET_CONFIRMATION_BULK_DELETE_TRANSACTIONS_MODAL_TOGGLE: (state, val) => {
      state.confirmationBulkDeleteTransactionModalToggle = val
    },
    SET_IS_BULK_DELETE_LOADING: (state, val) => {
      state.isBulkDeleteLoading = val
    },
    SET_HOLD_PAYMENT_ITEM: (state, val) => {
      state.holdPaymentItem = val
    },
    SET_RESUME_PAYMENT_ITEM: (state, val) => {
      state.resumePaymentItem = val
    },
    SET_FILTER_PARAMS: state => {
      const query = { ...router.currentRoute.query }
      state.currentPage = Number(query.currentPage) || 1
      state.perPage = query.perPage || DEFAULT_PER_PAGE
      state.searchQuery = query.searchQuery || ''
      state.filterPaymentMethod = query.filterPaymentMethod || ''
      state.filterStatus = query.filterStatus || ''
      state.filterDate = query.filterDate || ''
      state.tableViewMode = query.tableViewMode || 'All Transactions'
    },
    CLEAR_FILTERS: state => {
      state.filterPaymentMethod = ''
      state.filterStatus = ''
      state.filterDate = ''
    },
    RESET_STATE: state => {
      Object.assign(state, getDefaultState())
    },
    SET_MARKED_ELEMENTS: (state, val) => {
      state.markedElements = val
    },
    SET_ELEMENTS_PER_PAGE_CHECKBOX: (state, val) => {
      state.elementsPerPageCheckbox = val
    },
    SET_IS_CHARGE_DELETING: (state, val) => {
      state.isChargeDeleting = val
    },
  },
  actions: {
    async fetchTransactionsList({
      commit, getters, rootGetters,
    }) {
      try {
        const queryParams = getters.getFiltersParams

        if (getters.getIsProgramTransactions) queryParams.programId = rootGetters['verticalMenu/getDefaultProgram']

        const response = await axios.get('/auth/transactions', { params: queryParams })

        const {
          total, from, to,
        } = response.data.meta

        const dataMeta = {
          from,
          to,
          of: total,
        }

        commit('SET_TOTAL_ITEMS', total)
        commit('SET_DATA_META', dataMeta)
        return response.data.data
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Transactions',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return []
      }
    },
  },
}
