export default [
  { key: 'show_details', label: '#', selected: true },
  { key: 'id', label: 'Id', selected: true },
  {
    key: 'user_id', label: 'User ID', selected: true, sortable: true,
  },
  {
    key: 'family_id', label: 'Family ID', selected: true, sortable: true,
  },
  {
    key: 'payment_date', label: 'Date', selected: true, sortable: true,
  },
  {
    key: 'payer_name', label: 'Payer Name', selected: true, sortable: true,
  },
  {
    key: 'user_first_name', label: 'First Name', name: 'user_first_name', selected: true, sortable: true,
  },
  {
    key: 'user_last_name', label: 'Last Name', name: 'user_last_name', selected: true, sortable: true,
  },
  {
    key: 'program_name', label: 'Program Name', selected: true, sortable: true,
  },
  {
    key: 'model_type', label: 'Type', selected: true, sortable: true,
  },
  {
    key: 'amount', label: 'Amount', selected: true, sortable: true,
  },
  {
    key: 'description', label: 'Description', selected: true, sortable: true,
  },
  {
    key: 'status', label: 'Status', selected: true, sortable: true,
  },
  {
    key: 'type', label: 'Payment Method', selected: true, sortable: true,
  },
  { key: 'remaining_total', label: 'Running Balance', selected: true },
  { key: 'charge_balance', label: 'Charge Balance', selected: true },
  { key: 'ifp_balance', label: 'Running Balance (IFN)', selected: true },
  {
    key: 'notes', label: 'Internal Notes', selected: true, sortable: true,
  },
  {
    key: 'check_number', label: 'Ref #', selected: true, sortable: true,
  },
  {
    key: 'check_date', label: 'Check date', selected: true, sortable: true,
  },
  { key: 'actions', label: 'Actions', selected: true },
]
