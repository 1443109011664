<template>
  <div>
    <b-card
      v-if="!isApplicationTransactions && !isParentTransactions"
    >
      <h2 class="mb-2">
        Search Filter
      </h2>

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="12"
          md="4"
          class="w-100 mb-2"
        >
          <v-select
            v-model="filterPaymentMethod"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterPaymentMethodOptions"
            :clearable="false"
            label="name"
            :reduce="option => option.name"
            :placeholder="'Select Payment Method'"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="w-100 mb-2"
        >
          <v-select
            v-model="filterStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterStatusOptions"
            :clearable="false"
            label="label"
            :reduce="option => option.label"
            :placeholder="'Select Status'"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="w-100 mb-2"
        >
          <flat-pickr
            v-model="filterDate"
            class="form-control"
            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', mode: 'range'}"
            :placeholder="'Select Date/Time'"
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              @click="clearFilters"
            >
              <span class="mr-25 align-middle">Reset filters</span>
            </b-button>
          </div>
        </b-col>

      </b-row>

    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <template
        v-if="showTableNameInHeader"
        #header
      >
        <h4
          class="d-flex align-items-center mb-0"
        >
          Transactions
        </h4>

        <feather-icon
          :aria-expanded="showOverviewTable ? 'true' : 'false'"
          :icon="showOverviewTable ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          size="20"
          class="align-middle text-body"
          aria-controls="collapse-transactions-table"
          @click="showOverviewTable = !showOverviewTable"
        />
      </template>

      <b-collapse
        id="collapse-transactions-table"
        v-model="showOverviewTable"
      >
        <div class="table-header m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-between mb-1 mb-md-0"
            >
              <div class="d-flex align-items-center">

                <label class="mr-1 mb-0">Show</label>
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :value="perPage"
                  :clearable="false"
                  :reduce="option => option.value"
                  class="per-page-selector d-inline-block ml-50 mr-1"
                  @input="updatePerPage"
                />

                <b-dropdown
                  variant="link"
                  toggle-class="d-flex align-items-center"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="text-primary"
                    />
                    <h5 class="mb-0 ml-1 text-primary">
                      {{ tableViewMode }}
                    </h5>
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="16"
                      class="ml-1 text-primary"
                    />
                  </template>

                  <b-dropdown-item
                    @click="tableViewMode = 'All Transactions'"
                  >
                    <h5>All Transactions</h5>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="tableViewMode = 'Charges'"
                  >
                    <h5>Charges</h5>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="tableViewMode = 'Payments'"
                  >
                    <h5>Payments</h5>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="tableViewMode = 'Refunds'"
                  >
                    <h5>Refunds</h5>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="tableViewMode = 'Unlinked'"
                  >
                    <h5>Unlinked</h5>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="tableViewMode = 'CashBank'"
                  >
                    <h5>CashBank</h5>
                  </b-dropdown-item>
                </b-dropdown>
              </div>

              <div
                class="d-flex align-items-center justify-content-end"
              >
                <visible-columns-dropdown
                  class="mr-2"
                  :columns="tableColumns"
                  @saveVisibleColumns="saveVisibleColumns"
                />
                <!-- <b-button
                  variant="outline-primary"
                  class="mr-2"
                  @click="handleExportView"
                >
                  Export View
                </b-button>
                <b-button
                  v-if="showAddChargeButton"
                  variant="primary"
                  class="mr-2"
                  @click="handleAddCharge"
                >
                  Add Charge
                </b-button>
                <b-button
                  v-if="showMakePaymentButton"
                  variant="primary"
                  class="mr-2"
                  @click="handleAddPayment"
                >
                  Make Payment
                </b-button>
                <b-button
                  variant="primary"
                  class="mr-2"
                  @click="handleAddRefund"
                >
                  Add Refund
                </b-button> -->

                <template
                  v-if="!isApplicationTransactions && !isParentTransactions"
                >
                  <b-form-input
                    v-model="searchQuery"
                    placeholder="Search"
                    style="width: 150px"
                  />
                </template>
                <b-dropdown
                  variant="primary"
                  toggle-class="p-0"
                  no-caret
                  right
                  class="h-100 ml-1"
                >

                  <template
                    #button-content
                  >
                    <feather-icon
                      icon="GridIcon"
                      size="19"
                      class="m-50"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="showAddChargeButton"
                    @click="handleAddCharge"
                  >
                    <span>Add Charge</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="showMakePaymentButton"
                    @click="handleAddPayment"
                  >
                    <span>Make Payment</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="showAddRefundButton"
                    @click="handleAddRefund"
                  >
                    <span>Add Refund</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-b-modal.export-transactions-modal
                  >
                    <span>Export</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="isApplicationTransactions"
                    @click="exportFamilyReportToPdf"
                  >
                    <span>Export Statement</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refTransactionsTable"
          :items="fetchTransactionsList"
          :fields="visibleTableColumns"
          primary-key="id"
          responsive
          show-empty
          sticky-header="75vh"
          empty-text="No matching records found"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative table-sticky-head"
        >

          <!-- Loader -->
          <template #table-busy>
            <TableSpinner />
          </template>
          <template #head(show_details)>
            <div class="d-flex">
              <span>#</span>
              <b-form-checkbox
                v-model="elementsPerPageCheckbox"
                class="ml-1 bulk-checkbox"
                @change="markElementsOnPage"
              />
            </div>
          </template>
          <!-- Column: show_details -->
          <template #cell(show_details)="data">
            <div class="d-flex align-items-center">
              <feather-icon
                :icon="data.item._showDetails ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                size="16"
                class="align-middle text-body cursor-pointer mr-1"
                @click="data.toggleDetails"
              />
              <b-form-checkbox
                v-model="markedElements"
                :value="data.item.id"
                inline
                @change="handleElementsPerPageCheckbox"
              />
            </div>
          </template>

          <template #cell(#)="data">
            <div
              class="cursor-pointer text-primary"
            >
              <feather-icon
                icon="ChevronDownIcon"
                size="14"
                @click="data.toggleDetails"
              />
            </div>
          </template>

          <template #row-details="data">
            <payment-details-table
              v-if="data.item.model_type == 'Payment'"
              :payment-details="data.item.paidForTransactions"
              @updateTable="updateTable"
            />

            <charge-details-table
              v-if="data.item.model_type == 'Charge'"
              :charge-details="data.item.paidFromTransactions"
              @updateTable="updateTable"
            />
          </template>

          <!-- Column: Id -->
          <template #cell(id)="data">
            <span>
              {{ data.item.id | shortId }}
            </span>
          </template>

          <!-- Column: User Id -->
          <template #cell(user_id)="data">
            <span>
              {{ data.item.user.id }}
            </span>
          </template>

          <!-- Column: Family Id -->
          <template #cell(family_id)="data">
            <span>
              {{ data.item.user.family_id }}
            </span>
          </template>

          <!-- Column: Date -->
          <template #cell(payment_date)="data">
            <span :id="data.item.id">{{ getPayDate(data.item) | shortDate }}</span>
            <b-tooltip
              triggers="hover"
              :target="data.item.id"
              :delay="{ show: 100, hide: 50 }"
            >
              {{ getPayDate(data.item) | longDateTime }}
            </b-tooltip>
          </template>

          <!-- Column: Payer name -->
          <template #cell(payer_name)="data">
            <b-link
              :to="{ name: 'admin-application-edit', params: { id: data.item.application_id, type: getApplicationUserType(data.item.user) } }"
            >{{ data.item.user.full_name }}</b-link>
          </template>

          <!-- Column: User First Name -->
          <template #cell(user_first_name)="data">
            {{ data.item.user.first_name }}
          </template>

          <!-- Column: User Last Name -->
          <template #cell(user_last_name)="data">
            {{ data.item.user.last_name }}
          </template>

          <!-- Column: Program name -->
          <template #cell(program_name)="data">
            <span>{{ data.item.program_name }}</span>
          </template>

          <!-- Column: Amount -->
          <template #cell(amount)="data">
            <span>
              {{ data.item.amount | roundUpAmount }}
            </span>
          </template>

          <!-- Column: CHARGE BALANCE -->
          <template #cell(charge_balance)="data">
            <span>
              {{ data.item.charge_id ? data.item.charge_balance : '' }}
            </span>
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveStatusVariant(data.value)}`"
            >
              {{ data.value }}
            </b-badge>
          </template>

          <!-- Column: status -->
          <template #cell(notes)="data">
            <div
              class="d-flex"
            >
              <p class="mr-1">
                {{ data.value }}
              </p>
              <feather-icon
                v-if="$can('update', permissionSubjects.Charge)"
                icon="Edit2Icon"
                size="14"
                class="text-primary cursor-pointer"
                @click="showEditNotesModal(data.item)"
              />
            </div>
          </template>

          <!-- Column: Check Date -->
          <template #cell(check_date)="data">
            <span :id="data.item.id+data.value">{{ data.value | shortDate }}</span>
            <b-tooltip
              triggers="hover"
              :target="data.item.id+data.value"
              :delay="{ show: 100, hide: 50 }"
            >
              {{ data.value | longDateTime }}
            </b-tooltip>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <!-- Dropdown -->
            <!-- Dropdown -->
            <b-dropdown
              v-if="$can('edit', permissionSubjects.Payment)"
              variant="link"
              toggle-class="mx-auto p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-if="checkIsPaymentEditable(data.item)"
                @click="showEditPaymentModal(data.item)"
              >
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.model_type == 'Charge' && data.item.type != 'Refund' && canUpdateCharge"
                @click="showEditChargeModal(data.item.charge_id)"
              >
                <span>Edit Charge</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.type == 'Refund' && canUpdateCharge"
                @click="showEditRefundChargeModal(data.item.id)"
              >
                <span>Edit Refund</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="checkIsPaymentRefundable(data.item)"
                @click="showPaymentRefundModal(data.item)"
              >
                <span>Refund</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.model_type == 'Charge' && data.item.status.toLowerCase() === 'completed' && data.item.type !== 'Refund' && canCancelCharge"
                @click="showCancelChargeModal(data.item.charge_id)"
              >
                <span>Cancel Charge</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status.toLowerCase() === 'manual cancel'"
                @click="undoChargeCancel(data.item.charge_id)"
              >
                <span>Undo</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status.toLowerCase() == 'payment failed'"
                @click="transactionRetry(data.item.id)"
              >
                <span>Retry Payment</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="isAvailableToPause(data.item)"
                @click="showHoldPayments(data.item)"
              >
                <span>Pause Payment</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="isAvailableToResume(data.item)"
                @click="showResumePayments(data.item)"
              >
                <span>Resume Payment</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="checkIsPaymentDeletable(data.item)"
                @click="openDeleteConfirmation(data.item)"
              >
                <span>Delete</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="checkIsChargeDeletable(data.item)"
                @click="openDeleteChargeConfirmation(data.item)"
              >
                <span>Delete Charge</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-if="totalItems && perPage !== 'all'"
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center mt-2"
            >
              <b-dropdown
                id="dropdown-buttons"
                text="Bulk Actions"
                variant="outline-danger"
              >
                <b-dropdown-item
                  v-if="$can('update', permissionSubjects.Charge)"
                  id="bulk-change-status"
                  :disabled="markedElements.length < 1"
                  @click="showBulkChangeStatusModal"
                >
                  Bulk Change Status
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('delete', permissionSubjects.Charge) && $can('refund', permissionSubjects.Payment)"
                  :disabled="markedElements.length < 1"
                  @click="confirmationBulkDeleteTransactionModalToggle = true"
                >
                  Bulk Delete Transactions
                </b-dropdown-item>
                <b-tooltip
                  :disabled="isAllPending"
                  target="bulk-change-status"
                  triggers="hover"
                >
                  Only 'Pending' transactions can be changed'
                </b-tooltip>
              </b-dropdown>
            </b-col>

          </b-row>
        </div>

      </b-collapse>

    </b-card>

    <EditNotesModal
      v-if="selectedTransaction"
      :item="selectedTransaction"
      @refetchData="updateTable"
    />

    <BulkChangeTransactionsStatusModal
      :marked-elements="markedElements"
      @success="bulkActionSuccess"
    />

    <ApplicationSelectionModal
      :program-id="programId"
      @selectApplication="selectApplication"
    />

    <AddingChargeModal
      v-if="selectedApplicationId"
      :application-id="selectedApplicationId"
      :program-id="programId"
      @success="updateTable"
    />

    <PaymentPopup
      :popup-type="popupType"
      :user-id="selectedUserId"
      :card-id="selectedCardId"
      :application-id="selectedApplicationId"
      :editable-payment-item="selectedTransaction"
      :is-manual-upcoming-payment="true"
      :title="paymentPopupTitle"
      :button-text="paymentPopupBtnTitle"
      @paymentAdded="updateTable"
    />

    <CancelChargeModal
      @confirm="chargeCancel"
    />

    <PaymentRefundModal
      v-if="transactionToRefund"
      :transaction="transactionToRefund"
      @acceptRefund="acceptRefund"
    />

    <ManualPaymentRefundModal
      @acceptRefund="acceptRefund"
    />

    <confirmation-modal
      :toggle-modal="confirmationModalToggle"
      :type="'-delete-payment'"
      :title="'Are you sure'"
      :message="'Are you sure you want to delete this application payment?'"
      @close-modal="confirmationModalToggle = !confirmationModalToggle"
      @confirm="deleteApplicationPayment"
    />

    <delete-charge-modal
      :is-loading="isChargeDeleting"
      @deleteCharge="deleteApplicationCharge"
    />

    <confirmation-modal
      :toggle-modal="confirmationBulkDeleteTransactionModalToggle"
      :type="'-bulk-delete-transactions'"
      :title="'Are you sure'"
      :message="bulkDeleteTransactionsMessage"
      :is-loading="isBulkDeleteLoading"
      @close-modal="confirmationBulkDeleteTransactionModalToggle = !confirmationBulkDeleteTransactionModalToggle"
      @confirm="bulkDelTransactions"
    />

    <!-- Hold Payments Modal -->
    <hold-payments-modal
      :hold-pay-condition-transaction="holdPaymentItem"
      :application-id="selectedApplicationId"
      @updateData="updateTable"
    />

    <!-- Resume Payments Modal -->
    <resume-payments-modal
      :resume-pay-condition-transaction="resumePaymentItem"
      :application-id="selectedApplicationId"
      @updateData="updateTable"
    />

    <!-- Edit Charge Modal -->
    <edit-charge-modal
      :charge-id="selectedChargeId"
      @success="updateTable"
    />

    <!-- Add Refund Modal -->
    <adding-refund-modal
      :application-id="selectedApplicationId"
      @success="updateTable"
    />

    <edit-refund-modal
      :refund-id="selectedRefundChargeId"
      @success="updateTable"
    />

    <info-modal
      :modal-name="infoModalName"
      :title="''"
      :message="'Only \'Pending\' transactions can be changed'"
    />

    <export-transactions-modal
      :title="'Export Transactions'"
      :selected-params="selectedFiltersParams"
      :per-page-options="perPageOptions"
      :filter-status-options="filterStatusOptions"
      :filter-payment-method-options="filterPaymentMethodOptions"
      :table-view-mode="tableViewMode"
      @handleExport="exportTransactions"
    />
  </div>

</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
  BButton,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BBadge,
  BLink,
  BFormCheckbox, BCollapse,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import { filters } from '@core/mixins/filters'
import { transactionsMixin } from '@/views/admin/transactions-v2/mixins/transactionsMixin'
import EditNotesModal from '@/views/admin/transactions-v2/modals/EditNotesModal.vue'
import PaymentDetailsTable from '@/views/admin/transactions-v2/transaction-details/PaymentDetailsTable.vue'
import ChargeDetailsTable from '@/views/admin/transactions-v2/transaction-details/ChargeDetailsTable.vue'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import PaymentPopup from '@/views/components/paymentPopup/PaymentPopup.vue'
import AddingChargeModal from '@/views/components/adding-charge-modal/AddingChargeModal.vue'
import ApplicationSelectionModal from '@/views/components/application-selection-modal/ApplicationSelectionModal.vue'
import VisibleColumnsDropdown from '@/views/components/visible-columns-dropdown/VisibleColumnsDropdown.vue'
import CancelChargeModal from '@/views/components/cancel-charge-modal/CancelChargeModal.vue'
import PaymentRefundModal from '@/views/components/payment-refund-modal/PaymentRefundModal.vue'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import HoldPaymentsModal from '@/views/components/hold-payments-modal/HoldPaymentsModal.vue'
import ResumePaymentsModal from '@/views/components/hold-payments-modal/ResumePaymentsModal.vue'
import ManualPaymentRefundModal from '@/views/components/payment-refund-modal/ManualPaymentRefundModal.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import defaultColumns from '@/views/admin/transactions-v2/helpers/transactionColumns'
import EditChargeModal from '@/views/components/edit-charge-modal/EditChargeModal.vue'
import EditRefundModal from '@/views/components/edit-refund-modal/EditRefundModal.vue'
import AddingRefundModal from '@/views/components/adding-refund-modal/AddingRefundModal.vue'
import BulkChangeTransactionsStatusModal from '@/views/components/bulk-change-transactions-status/BulkChangeTransactionsStatusModal.vue'
import InfoModal from '@/views/components/info-modal/InfoModal.vue'
import DeleteChargeModal from '@/views/components/delete-charge-modal/DeleteChargeModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import ExportTransactionsModal from '@/views/components/transactions-export-pdf-modal/ExportTransactionsModal.vue'

let debounceTimeout = null

export default {
  name: 'TransactionsList',
  components: {
    BCollapse,
    ExportTransactionsModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BButton,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BBadge,
    BLink,
    BFormCheckbox,

    flatPickr,
    vSelect,

    TableSpinner,
    EditNotesModal,
    PaymentPopup,
    AddingChargeModal,
    ApplicationSelectionModal,
    VisibleColumnsDropdown,
    PaymentDetailsTable,
    ChargeDetailsTable,
    CancelChargeModal,
    PaymentRefundModal,
    ConfirmationModal,
    HoldPaymentsModal,
    ResumePaymentsModal,
    ManualPaymentRefundModal,
    EditChargeModal,
    EditRefundModal,
    AddingRefundModal,
    BulkChangeTransactionsStatusModal,
    InfoModal,
    DeleteChargeModal,
  },
  mixins: [filters, transactionsMixin],
  props: {
    userData: {
      type: Object,
      default: null,
    },
    familyId: {
      type: [String, Number],
      default: null,
    },
    showTableNameInHeader: {
      type: Boolean,
      default: false,
    },
    refetchData: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      permissionSubjects,
      showOverviewTable: true,
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.updateTable()
    },
    programId() {
      this.updateTable()
    },
    refetchData() {
      this.updateTable()
    },
    tableViewMode(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'tableViewMode', val)
      }
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
    filterPaymentMethod(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'filterPaymentMethod', val)
      }
    },
    filterStatus(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'filterStatus', val)
      }
    },
    filterDate(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'filterDate', val)
      }
    },
  },
  async created() {
    this.setTransactionsViewColumns()

    this.$store.commit('app-transactions/SET_CURRENT_ROUTE_NAME', this.$route.name)
    if (this.isApplicationTransactions) {
      this.$store.commit('app-transactions/SET_APPLICATION_ID', this.$route.params.id)
      this.$store.commit('app-transactions/SET_APPLICATION_ID', this.$route.params.id)
    }
    if (this.isParentTransactions) {
      this.$store.commit('app-transactions/SET_FAMILY_ID', this.familyId)
    }

    this.setFilterParams()

    await this.setPaymentMethodsList()
    await this.setTransactionsStatusesList()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  beforeDestroy() {
    this.$store.commit('app-transactions/RESET_STATE')
  },
  methods: {
    setTransactionsViewColumns() {
      const localStorageColumns = localStorage.getItem('transactionsTableColumns') ? JSON.parse(localStorage.getItem('transactionsTableColumns')) : []

      if (localStorageColumns.length > 0) {
        const localStorageColumnsKeys = localStorageColumns.map(col => col.key)
        const defaultTableColumnsKeys = defaultColumns.map(col => col.key)
        const differentColumns = defaultTableColumnsKeys
          .filter(key => !localStorageColumnsKeys.includes(key))
          .concat(localStorageColumnsKeys.filter(key => !defaultTableColumnsKeys.includes(key)))

        const localStorageColumnsNames = localStorageColumns.map(col => col.label)
        const defaultTableColumnsNames = defaultColumns.map(col => col.label)
        const differentColumnsNames = defaultTableColumnsNames
          .filter(name => !localStorageColumnsNames.includes(name))
          .concat(localStorageColumnsNames.filter(name => !defaultTableColumnsNames.includes(name)))

        const localStorageColumnsSortableSetting = localStorageColumns.map(col => col.label + (col.sortable ? '1' : '0'))
        const defaultTableColumnsSortableSetting = defaultColumns.map(col => col.label + (col.sortable ? '1' : '0'))

        const differentColumnsSortableSetting = defaultTableColumnsSortableSetting
          .filter(name => !localStorageColumnsSortableSetting.includes(name))

        if (differentColumns.length > 0 || differentColumnsNames.length > 0 || differentColumnsSortableSetting.length > 0) {
          this.$store.commit('app-transactions/SET_TABLE_COLUMNS', JSON.parse(JSON.stringify(defaultColumns)))
        } else {
          this.$store.commit('app-transactions/SET_TABLE_COLUMNS', JSON.parse(JSON.stringify(localStorageColumns)))
        }
        return
      }
      this.$store.commit('app-transactions/SET_TABLE_COLUMNS', JSON.parse(JSON.stringify(defaultColumns)))
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .per-page-selector {
    width: 90px;
  }

</style>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
