<template>
  <b-dropdown
    id="visible-columns-dropdown"
    ref="visibleColumnsDropdown"
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    right
    variant="flat-primary"
    no-caret
    no-flip
    lazy
    @click.native.stop
    @show="setOptions"
  >
    <template #button-content>
      <feather-icon
        icon="EyeIcon"
      />
      <span class="pl-1">Visibility Options</span>
    </template>
    <b-dropdown-form>
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="mb-25 pr-4 font-weight-bolder">
          Visible Columns
        </h5>
      </div>

      <hr>
      <draggable
        v-model="columnsList"
        class="visible-columns-options"
      >
        <template
          v-for="column in columnsList"
        >
          <div
            v-if="column.key !== 'actions'"
            :key="column.key"
            class="mb-2"
          >
            <b-form-checkbox
              :id="column.key"
              v-model="column.selected"
            >
              {{ column.label }}
            </b-form-checkbox>
          </div>
        </template>
      </draggable>

      <hr>

      <b-row>
        <b-col
          cols="8"
        >
          <b-form-group
            label="Sort By Field"
            label-class="font-weight-bolder py-1"
          >
            <v-select
              v-model="sortByFieldOption"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="columns"
              label="label"
              :reduce="option => option.key"
              :clearable="false"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
          class="pl-2"
        >
          <b-form-group
            label="Sort by Desc"
            label-cols="12"
            label-class="font-weight-bolder py-1"
          >
            <b-form-checkbox
              v-model="isSortByDescOption"
              class="mr-0 mt-50"
              name="is-rtl"
              switch
              inline
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr>

      <div class="d-flex justify-content-between">
        <b-button
          variant="outline-primary"
          @click="cancel"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="saveSelectedColumns"
        >
          Save
        </b-button>
      </div>
    </b-dropdown-form>

  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BDropdownForm,
  BFormCheckbox,
  BButton,
  BCol,
  BFormGroup,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'

export default {
  name: 'VisibleColumnsDropdown',
  components: {
    BRow,
    BFormGroup,
    BCol,
    BDropdown,
    BDropdownForm,
    BFormCheckbox,
    BButton,
    vSelect,
    draggable,
  },
  directives: {
    Ripple,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    sortByField: {
      type: String,
      default: 'created_at',
    },
    isSortByDesc: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      columnsList: [],
      sortByFieldOption: '',
      isSortByDescOption: false,
    }
  },
  methods: {
    closeDropdown() {
      this.$refs.visibleColumnsDropdown.hide()
    },
    setOptions() {
      this.columnsList = JSON.parse(JSON.stringify(this.columns))
      this.sortByFieldOption = this.sortByField
      this.isSortByDescOption = this.isSortByDesc
    },
    saveSelectedColumns() {
      this.$emit('saveVisibleColumns', this.columnsList, this.sortByFieldOption, this.isSortByDescOption)
      this.closeDropdown()
    },
    cancel() {
      this.closeDropdown()
    },
  },
}
</script>

<style lang="scss" scoped>
.visible-columns-options {
  width: 400px;
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-button {
      height: 1px;
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #D8D6DE;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #7367F0;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: darken(#7367F0, 5%);
    }
}

</style>
