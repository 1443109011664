<template>
  <div
    class="mb-2"
  >
    <b-card
      class="pt-1"
      bg-variant="light"
      border-variant="secondary"
    >
      <b-row>
        <b-col
          sm="12"
        >
          <b-form-group
            label="Credit Card Number"
          >
            <div
              :id="'card-number-' + stripeItemID"
              class="stripe_row form-control"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="6"
        >
          <b-form-group
            label="Expiry Date"
          >
            <div
              :id="'card-expiry-' + stripeItemID"
              class="stripe_row form-control"
            />
          </b-form-group>
        </b-col>

        <b-col
          sm="6"
        >
          <b-form-group
            label="CVC/CVV"
          >
            <div
              :id="'card-cvc-' + stripeItemID"
              class="stripe_row form-control"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div
        v-if="hasCardError"
        :id="'payment-message-' + stripeItemID"
        class="form-group pt-1"
      >
        <p
          v-if="errorNumber"
          class="text-center text-danger"
        >
          {{ errorNumber }}
        </p>
        <p
          v-if="errorCvc"
          class="text-center text-danger"
        >
          {{ errorCvc }}
        </p>
        <p
          v-if="errorExpiry"
          class="text-center text-danger"
        >
          {{ errorExpiry }}
        </p>
      </div>
    </b-card>
    <b-row>
      <b-col
        sm="6"
      >
        <b-button
          variant="outline-primary"
          class="w-100"
          @click="cancel"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col
        sm="6"
      >
        <b-button
          variant="primary"
          class="w-100"
          @click="saveCard"
        >
          Save Card
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BButton,
} from 'bootstrap-vue'
import { loadStripe } from '@stripe/stripe-js'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      errorNumber: null,
      errorExpiry: null,
      errorCvc: null,
      publishableKey: null,
      stripeItemID: '',
    }
  },
  computed: {
    hasCardError() {
      return !!this.errorNumber || !!this.errorExpiry || !!this.errorCvc
    },
  },
  beforeMount() {
    this.schoolData = JSON.parse(localStorage.getItem('schoolData'))
    this.publishableKey = this.schoolData.config.public_stripe_key
    this.stripeItemID = 1
  },
  async mounted() {
    // Load Stripe.js with your publishable key
    this.stripe = await loadStripe(this.publishableKey)
    const elements = this.stripe.elements()

    const style = {
      style: {
        base: {
          fontSize: '1rem',
          color: '#6e6b7b',
          fontWeight: '400',
          fontFamily: 'Montserrat, Helvetica, Arial, serif',
          backgroundColor: '#fff',
          '::placeholder': {
            color: '#ceced3',
            fontWeight: '400',
            fontSize: '1rem',
            fontFamily: 'Montserrat, Helvetica, Arial, serif',
          },
        },
      },
    }

    // Create and mount the card number input field
    this.cardNumber = elements.create('cardNumber', style)
    this.cardNumber.mount(`#card-number-${this.stripeItemID}`)
    this.cardNumber.addEventListener('change', event => {
      if (event.error) {
        this.errorNumber = event.error.message
      } else {
        this.errorNumber = null
      }
    })

    this.cardExpiry = elements.create('cardExpiry', style)
    this.cardExpiry.mount(`#card-expiry-${this.stripeItemID}`)
    this.cardExpiry.addEventListener('change', event => {
      if (event.error) {
        this.errorExpiry = event.error.message
      } else {
        this.errorExpiry = null
      }
    })

    this.cardCvc = elements.create('cardCvc', style)
    this.cardCvc.mount(`#card-cvc-${this.stripeItemID}`)
    this.cardCvc.addEventListener('change', event => {
      if (event.error) {
        this.errorCvc = event.error.message
      } else {
        this.errorCvc = null
      }
    })
  },
  beforeDestroy() {
    if (this.cardNumber) {
      this.cardNumber.destroy()
    }
    if (this.cardExpiry) {
      this.cardExpiry.destroy()
    }
    if (this.cardCvc) {
      this.cardCvc.destroy()
    }
  },
  methods: {
    resetCardData() {
      if (this.cardNumber) {
        this.cardNumber.destroy()
      }
      if (this.cardExpiry) {
        this.cardExpiry.destroy()
      }
      if (this.cardCvc) {
        this.cardCvc.destroy()
      }
    },
    cancel() {
      this.$emit('cancel')
      this.resetCardData()
    },
    async createToken() {
      const { token, error } = await this.stripe.createToken(this.cardNumber)
      if (error) {
        // handle error here
        document.getElementById(`#${this.stripeItemID}card-error`).innerHTML = error.message
        return null
      }

      return token
    },
    async saveCard() {
      if (this.hasCardError) {
        return
      }

      const token = await this.createToken()
      if (token) {
        this.$emit('addNewCard', token)
      }
    },
  },
}
</script>

<style>
.stripe_row {
  padding: 0.6rem 1rem;
}
</style>
